import React, { useState } from "react";
import styled from "styled-components";
import {
  Grid,
  Card,
  CardMedia,
  Modal,
  Box,
  IconButton,
  Container,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import YouTube from "react-youtube";
import CategoryTitle from "./CategoryTitle";

const FullWidthContainer = styled(Container)`
  padding-left: 0 !important;
  padding-right: 0 !important;
  max-width: 100% !important;
`;

const StyledCard = styled(Card)`
  height: 100%;
  display: flex;
  background-color: transparent;
  flex-direction: column;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
`;

const StyledCardMedia = styled(CardMedia)`
  padding-top: 56.25%; // 16:9 aspect ratio
`;

const ModalContent = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 800px;
  background-color: #ffffff;
  border: 2px solid #000000;
  box-shadow: 24px;
  padding: 16px;

  @media (max-width: 600px) {
    width: 95%;
    padding: 8px;
  }
`;

const StyledYouTube = styled(YouTube)`
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
`;

const VideoSection = ({ name, videos }) => {
  const [selectedVideo, setSelectedVideo] = useState(null);

  const handleVideoClick = (videoId) => {
    setSelectedVideo(videoId);
  };

  const handleCloseModal = () => {
    setSelectedVideo(null);
  };

  const opts = {
    width: "100%",
    height: "100%",
    playerVars: {
      autoplay: 1,
    },
  };

  return (
    <FullWidthContainer>
      <CategoryTitle>{name}</CategoryTitle>
      <Grid container spacing={2}>
        {videos.map((videoId) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={videoId}>
            <StyledCard onClick={() => handleVideoClick(videoId)}>
              <StyledCardMedia
                image={`https://img.youtube.com/vi/${videoId}/0.jpg`}
                title="Video thumbnail"
              />
            </StyledCard>
          </Grid>
        ))}
      </Grid>
      <Modal
        open={Boolean(selectedVideo)}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContent>
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "#808080",
            }}
          >
            <CloseIcon />
          </IconButton>
          {selectedVideo && (
            <StyledYouTube videoId={selectedVideo} opts={opts} />
          )}
        </ModalContent>
      </Modal>
    </FullWidthContainer>
  );
};

export default VideoSection;
