import React from "react";
import { Typography } from "@mui/material";
import styled from "styled-components";

import logoola from "./logo.png";
import stu from "./stu.jpeg";

const AppContainer = styled.div`
  display: grid;
  place-items: center;
  font-family: "Castoro", serif;
`;
const ContentContainer = styled.div`
  padding: 34px;

  font-family: "Castoro", serif;
  max-width: 945px;
  display: flex;
  flex-direction: column;
`;

const StyledHeading = styled(Typography)`
  && {
    margin-top: 0;
    margin-bottom: 24px;
    font-size: 2rem;
    font-weight: bold;
    color: #2c3e50;
    text-align: center;
  }
`;

const StyledParagraph = styled(Typography)`
&& {
  margin-bottom: 23px;
  color: black;
  font-size: 1.2rem;
  font-family: "Castoro", serif;
  line-height: 1.6;
  text-align: justify;
`;

const StyledHighlight = styled.span`
  color: #d35400;
  font-weight: bold;
`;
const StyledImage = styled.img`
  /* margin-bottom: -3rem; */
  width: 100%;
  max-width: 166px;
  height: auto;
  display: flex;
  margin-bottom: 40px;
  margin-top: -15px;
  @media (max-width: 768px) {
    max-width: 300px; // Smaller size on smaller screens
  }
`;
const Studio = () => {
  return (
    <AppContainer>
      <ContentContainer>
        <StyledParagraph>
          <a
            href="https://haulpaniya.netlify.app/"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: "black",
              textDecoration: "none",
            }}
          >
            <StyledHighlight>"HAULPANIYA"</StyledHighlight>
          </a>{" "}
          is my intimate production house and recording studio. To me, it's like
          a home—always welcoming with open arms. I established it to
          successfully record and release my music to the world. However, over
          the years, word spread, and various talented musicians, directors,
          artists, and producers began to visit.
        </StyledParagraph>
        <StyledParagraph variant="body1">
          Each of them was seeking a unique approach to their projects. Some
          requested that I arrange violin and wind instrument parts, while
          others wanted me to record the piano, bass, guitars, or even drums.
          Some asked me to mix their songs on my analog console, while others
          requested that I compose and produce an original soundtrack for their
          play or movie.
        </StyledParagraph>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <StyledImage src={logoola} alt="Studio" />
        </div>
        <StyledParagraph variant="body1">
          Thanks to "Haulpaniya," I've met inspiring people who have become some
          of my best friends over the years. Today, I get the feeling that
          "Haulpaniya" for them is what it was to me when it all began—a home.
        </StyledParagraph>
        <StyledParagraph variant="body1">
          If you feel the studio and I can assist you with anything, feel free
          to leave a message.
          <br />
          —Yoav Asif
        </StyledParagraph>
      </ContentContainer>
    </AppContainer>
  );
};

export default Studio;
