import React from "react";
import styled from "styled-components";
import { Container, useMediaQuery } from "@mui/material";
import VideoSection from "./VideoSection";
import { Computercategories, Phonecategories } from "./categories";
import { SoundCloudSection } from "./SoundCloudSection";

const BackgroundWrapper = styled.div`
  background-color: "#000000
  min-height: 100vh;
  padding-bottom: 2rem;
`;

const StyledContainer = styled(Container)`
  padding-top: 2rem;
  padding-bottom: 2rem;
`;

const RecordingStudioWebsite = () => {
  const isMobile = useMediaQuery("(max-width:600px)"); // בודק אם זה מסך פלאפון
  const categories = isMobile ? Phonecategories : Computercategories; // בוחר את הקטגוריות בהתאם לגודל המסך

  return (
    <BackgroundWrapper>
      <StyledContainer>
        {categories.map((category, index) => (
          <React.Fragment key={category.name}>
            {category.videos ? (
              <VideoSection name={category.name} videos={category.videos} />
            ) : category.soundcloud ? (
              <SoundCloudSection
                name={category.name}
                soundcloudUrl={category.soundcloud.src}
                fallbackInfo={{
                  title: "Winning Shot EP - Trailerized Rock",
                  artist: "Composed by Dor Rozen & Yoav Asif",
                  description:
                    "Original soundtrack for 'Winning Shot'. Badass guitar riffs, electric bass grooves and bragging rock drums for those who can rewrite the rules! Featuring modern sound design, intense metal-core drums and dirty bass guitar packed with an oriental shade.",
                }}
              />
            ) : null}
          </React.Fragment>
        ))}
      </StyledContainer>
    </BackgroundWrapper>
  );
};

export default RecordingStudioWebsite;
