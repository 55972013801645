export const Phonecategories = [
  {
    name: "MUSIC PRODUCTION ",
    videos: [
      "FIJLMfCJDuM",
      "WnYd4qW6isM",
      "G3MQIDd6zFs",
      "la0MRpxvtRI",
      "mlOXQjT-yUE",
      "njZ2X0uAKII",
      "eFIGECvps6s",
      "43mryxnSlMY",
      "eTgkoqalwDc",
      "sBzFZdRt-PU",
      "4rWm0YtFyIc",
      "vTxCi_ZIE3g",
    ],
  },
  {
    name: "MIX & MASTERING",
    videos: [
      "0-JAqKkY1JQ",
      "dxIRnYFfVBI",
      "fOg5cJ02X9Q",
      "raNrLHbXxPI",
      "Yp0LplR4-BU",
      "lfsrYBofJe4",
      "gYeKvm1KY4s",
      "DVqjSUxG0cA",
    ],
  },
  {
    name: "COMMERCIALS",
    videos: [
      "S9V_yHypmh4",
      "B9Cq1GLg8AY",
      "eKYHcya1If8",
      "OA5_n4iqjv0",
      "GoQ1wHFZ5dU",
      "R2qwi7HYe74",
      "iktxF70oEVo",
      "xSnw4toU6a4",
    ],
  },
  {
    name: "FILM • TV THEATRE",
    videos: [
      "K4NbGOj8yqY",
      "gMb2ZHJZdFQ",
      "2lWLvZxuwbk",
      "1N_ltD7bEyU",
      "NugYs6QqcHA",
      "_QG1T-MkJHQ",
      "oGC97wdh6ho",
      "ftUGKHACoGk",
      "clFxWXBx0-U",
      "IlX61ZOm1sY",
      "BetXc13Qy8w",
      "zgW1Vu11zV4",
    ],
  },
  {
    name: "TRAILERS",
    soundcloud: {
      src: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/855132647%3Fsecret_token%3Ds-WlCfv&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
      title:
        "Winning Shot EP - Trailerized Rock (Composed By Dor Rozen & Yoav Asif)",
      artist: "Yoav Asif | Composer",
      link: "https://soundcloud.com/yoavasif/sets/winning-shot-composed-by-dor-rozen-yoav-asif/s-WlCfv",
    },
  },
];

export const Computercategories = [
  {
    name: "MUSIC PRODUCTION ",
    videos: [
      "FIJLMfCJDuM",
      "WnYd4qW6isM",
      "G3MQIDd6zFs",
      "la0MRpxvtRI",
      "mlOXQjT-yUE",
      "njZ2X0uAKII",
      "eFIGECvps6s",
      "43mryxnSlMY",
      "eTgkoqalwDc",
      "sBzFZdRt-PU",
      "4rWm0YtFyIc",
      "vTxCi_ZIE3g",
    ],
  },
  {
    name: "MIX & MASTERING",
    videos: [
      "0-JAqKkY1JQ",
      "dxIRnYFfVBI",
      "fOg5cJ02X9Q",
      "raNrLHbXxPI",
      "Yp0LplR4-BU",
      "lfsrYBofJe4",
      "gYeKvm1KY4s",
      "DVqjSUxG0cA",
    ],
  },
  {
    name: "COMMERCIALS",
    videos: [
      "S9V_yHypmh4",
      "B9Cq1GLg8AY",
      "eKYHcya1If8",
      "OA5_n4iqjv0",
      "GoQ1wHFZ5dU",
      "R2qwi7HYe74",
      "iktxF70oEVo",
      "xSnw4toU6a4",
    ],
  },
  {
    name: "FILM • TV • THEATRE",
    videos: [
      "K4NbGOj8yqY",
      "gMb2ZHJZdFQ",
      "2lWLvZxuwbk",
      "1N_ltD7bEyU",
      "NugYs6QqcHA",
      "_QG1T-MkJHQ",
      "oGC97wdh6ho",
      "ftUGKHACoGk",
      "clFxWXBx0-U",
      "IlX61ZOm1sY",
      "BetXc13Qy8w",
      "zgW1Vu11zV4",
    ],
  },
  {
    name: "TRAILERS",
    soundcloud: {
      src: "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/855132647%3Fsecret_token%3Ds-WlCfv&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
      title:
        "Winning Shot EP - Trailerized Rock (Composed By Dor Rozen & Yoav Asif)",
      artist: "Yoav Asif | Composer",
      link: "https://soundcloud.com/yoavasif/sets/winning-shot-composed-by-dor-rozen-yoav-asif/s-WlCfv",
    },
  },
];
