import React from "react";
import styled from "styled-components";
import { Typography } from "@mui/material";

const StyledCategoryTitle = styled(Typography)`
  @import url("https://fonts.googleapis.com/css2?family=Oswald:wght@500&family=Castoro&display=swap");

  font-family: "Castoro", sans-serif !important;
  text-align: center;
  font-weight: 400 !important;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  margin: 1rem auto;
  padding: 2.3rem;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  word-wrap: break-word;
  overflow-wrap: break-word;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;

  font-size: 2.75rem;
  line-height: 1.2;

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 2rem !important;
    padding: 1rem;
  }
`;

const WordSpan = styled.span`
  margin: 0 0.2em;
`;

const NoWrapSpan = styled.span``;

const CategoryTitle = ({ children }) => {
  const words = children.split(" ");

  return (
    <StyledCategoryTitle variant="h2" component="h2">
      {words.map((word, index) => {
        if (word === "PROD.") {
          return <NoWrapSpan key={index}>{word} </NoWrapSpan>;
        }
        return <WordSpan key={index}>{word}</WordSpan>;
      })}
    </StyledCategoryTitle>
  );
};

export default CategoryTitle;
